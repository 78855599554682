body {
    overflow: hidden;
}

.Dashboard-content-624 {
    overflow-y: scroll !important;
}


.Dashboard-content-624::-webkit-scrollbar {
    display: none;
}


/* Rewrite scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    z-index: 9999999;
}

/* Rewrite Rules For Y Axis Rules */
::-webkit-scrollbar:vertical{
    direction: rtl;
    width: 5px;
    height: calc(100% - 144px);
    z-index: 9999999;
}

/* Rewrite Rules For X AXIS Rules */
::-webkit-scrollbar-thumb:horizontal {
    width: 0.001px;
    height: 8px;
    z-index: 9999999;
}
 
 
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 1px;
    border-radius: 10px;
    background: #6A6C6D; 
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgb(53, 63, 62);
}


